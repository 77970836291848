import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

import Layout from "../components/Layout/layout"
import Seo from "../components/Seo/seo"
import ImageTextBlock from "../components/ImageTextBlock/imageTextBlock"
import { main } from "../styles/theme"

const HeadingWrapper = styled.div`
  margin: 46px 0 0;

  @media ${main.breakpoints.large} {
    margin: 196px 0 0;
  }
`

const CustomH1 = styled.h1`
  font-size: 13vw;
  line-height: 0.5;
  letter-spacing: -0.02em;
  font-weight: normal;
  margin: 0;
  white-space: break-spaces;

  @media ${main.breakpoints.small} {
    font-size: 14vw;
    line-height: 0.6;
  }
  @media ${main.breakpoints.medium} {
    font-size: 14.2vw;
    line-height: 0.6;
  }
  @media ${main.breakpoints.large} {
    font-size: 14.5vw;
    line-height: 0.6;
  }

  @media ${main.breakpoints.xlarge} {
    font-size: 14.8vw;
    line-height: 0.7;
  }
`

const Material = () => (
  <Layout>
    <Seo title="Material & Tillval" />
    <HeadingWrapper>
      <CustomH1>Material & tillval</CustomH1>
    </HeadingWrapper>
    <StaticImage
      src="../images/Final_Badrum_86-cropped.jpg"
      quality={95}
      layout="fullWidth"
      formats={["AUTO", "WEBP"]}
      alt="Material"
      style={{ marginTop: `2.45rem` }}
    />
    <ImageTextBlock
      preHeading="Färgval"
      text={[
        `Lägenheternas ljusa färgskalor är tidlöst vackra och naturnära. Väggarna går i en greige nyans med en svag hint av rött som bidrar med värme. Det ger en mjuk kontrast till det vita i taken och på listverken. Ett kök i en fröfärgad, greige kulör får fin kontrast med ett lite mörkare golv. En blå kulör i ett annat kök tar in havet tillsammans med ett ljust golv. Badrummen är inspirerade av den gotländska kalkstenen som följer upp tonerna i fasadens huggna natursten. Fönstrens insidor går även de i en greige, fröfärgad nyans.`,
      ]}
      imagePosition="right"
      offset={1}
      columns={6}
      image={
        <StaticImage
          src="../images/newhouse.jpg"
          quality={95}
          formats={["AUTO", "WEBP"]}
          alt="Sovrum"
          placeholder="blurred"
          layout="fullWidth"
        />
      }
    />
    <ImageTextBlock
      preHeading="Golv"
      text={[
        `Lägenhetens för evigt vackraste ”möbel” är ekparketten i enstavsläggning från klassiska Kährs. Den är mattlackad och lätt borstad för att skapa liv och framhäva träets struktur och känsla. Parkettens kanter är svagt avfasade. I lägenheternas badrum ligger behaglig värmeklinker.`,
      ]}
      imagePosition="left"
      offset={2}
      columns={5}
      image={
        <StaticImage
          src="../images/Final_Kitchen_150-cropped.jpg"
          quality={95}
          formats={["AUTO", "WEBP"]}
          alt="Townhouse interiör"
          placeholder="blurred"
          layout="fullWidth"
        />
      }
    />
    <ImageTextBlock
      preHeading="Kök"
      text={[
        `Lägenhetens snickerikök går i en tidlös och formren ton. Den takhöga inredningen kommer från familjeföretaget Taras Hallgren & Son och produceras i Partille. Designen är närmast minimalistisk med släta luckor och dolda grepplister. Lådorna är i massiv, mattlackad ek. Under överskåpen sitter spottar i borstad stål. Kyl, frys och diskmaskin är helintegrerade. Köket har även häll och ugn med möjlighet att välja till en kombiugn. Samtliga vitvaror är från Siemens och köksblandare från kvalitetssäkra Hafa. Bänkskivor och stänkskydd i tidlös Carrara marmor eller ljus Colonial White granit med inslag av svart, vitt och silver. Allt i en fin balans mellan det aktuella och det tidlösa.`,
      ]}
      imagePosition="right"
      offset={2}
      columns={4}
      image={
        <StaticImage
          src="../images/kitchen.jpg"
          quality={95}
          formats={["AUTO", "WEBP"]}
          alt="Köksdetaljer"
          placeholder="blurred"
          layout="fullWidth"
        />
      }
    />
    <ImageTextBlock
      preHeading="Förvaring"
      text={[
        `Lägenheternas garderober är 2,1 meter höga, också tillverkade av Taras Hallgren & Son i samma färg, utförande och kvalité som lägenheternas kök. Detta för att ge interiören ett enhetligt intryck. De individuella FTX-anläggningarna är även de på samma sätt integrerade i sin utformning, för att skapa harmoni.`,
        `Lägenhetsförråd återfinns på plan 1 och 2 i gårdshuset för de om 1-2 rok medan stadsradhusen i porthuset får en platsbyggd förvaring under den egna trappan.`,
      ]}
      imagePosition="left"
      offset={2}
      columns={5}
      image={
        <StaticImage
          src="../images/porthuset-interior.jpg"
          quality={95}
          formats={["AUTO", "WEBP"]}
          alt="Kök"
          placeholder="blurred"
          layout="fullWidth"
        />
      }
    />
    <ImageTextBlock
      preHeading="Badrum"
      text={[
        `Lägenhetens badrum har en mycket sober ton med golv och väggar i granitkeramik inspirerad av den gotländska kalkstenen. Ytan är naturtrogen med varierade detaljer som skapar liv i materialet. Plattorna på vägg och golv är 60X60 med fogar i liknande kulör med undantag i duschen där plattorna är något mindre för att skapa en bra lutning mot golvbrunnen. Alla golv i  badrummen har behaglig golvvärme för kalla morgnar. Badrummets kromade blandare kommer från Hafa och den lackade handfatskommoden har helgjutet handfat i slät designbetong. Badrummet har även handdukstork, spegelbelysning, infällda spottar i taket samt en spegel som är närmast heltäckande och skapar rymd. Krokar och hållare för toalettrulle kommer från Beslagsboden. I badrummet finns även en kombinerad tvättmaskin/torktumlare från LG. Vit wc och kromade detaljer ingår med möjlighet att istället välja svarta detaljer som blandare, wc och handdukstork.`,
      ]}
      imagePosition="right"
      offset={2}
      columns={4}
      image={
        <StaticImage
          src="../images/bathroom-cropped.jpg"
          quality={95}
          formats={["AUTO", "WEBP"]}
          alt="Badrumsdetaljer"
          placeholder="blurred"
          layout="fullWidth"
        />
      }
    />
  </Layout>
)

export default Material
